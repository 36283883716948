"use client";
import { useEffect } from "react";
import { useRouter } from "next/navigation";

export default function Custom404(): JSX.Element {
  const router = useRouter();

  useEffect(() => {
    router.push("/dashboard");
  });

  return <></>;
}
